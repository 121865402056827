
p {
	line-height:1.5;
	padding: 12px 0 12px;
}

h1 {
	font-size: 36px;
	text-transform:uppercase;
	text-align:center;
	letter-spacing: 16px;
	position:relative;
	margin-bottom:100px;
}

h1:after {
	content : "";
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	bottom: -30px;
	height: 4px;
	width: 70px;
	border-bottom:4px solid #fff;
}

.flex {
	display:flex;
}

.strong {
	font-weight: bold;
}

.nowrap {
	white-space: nowrap;
}

.margin-bottom-150 {
	margin-bottom: 150px;
}

.margin-bottom-100 {
	margin-bottom: 100px;
}

.margin-bottom-50 {
	margin-bottom: 50px;
}

.margin-top-150 {
	margin-top: 150px;
}

.margin-top-100 {
	margin-top: 100px;
}

.margin-top-50 {
	margin-top: 50px;
}



