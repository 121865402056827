.order-overlay,
.form-overlay {
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 100vh;
	background-color: rgba(256, 256, 256, .6);
	opacity:0;
	visibility: hidden;

	&.show {
		opacity:1;
		visibility: visible;
		z-index: 100;
	}
}

.order-box,
.form-box {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 950px;
	height: 615px;
	max-height: 90%;
	background-color: $bg-color;
	transform: translate(-50%, -50%);
	opacity:0;
	visibility: hidden;
	padding: 60px 60px 30px 60px;
	overflow-y: scroll;

	&.show {
		opacity:1;
		visibility: visible;
		z-index: 101;
	}

	&::-webkit-scrollbar {
	    width: 8px;
	}

	&::-webkit-scrollbar-track {
	    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
	    border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
	    border-radius: 4px;
	    background-color: $header-color;
	    // -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.5); 
	}

	.wpcf7-validation-errors {
		display:none !important;
	}

	.wpcf7-response-output {
		padding: 1em !important;
		text-align: center !important;
		font-weight: bold !important;
	}

	// .close-form {
	// 	cursor: pointer;
	// 	position: absolute;
	// 	top: 25px;
	// 	right: 25px;
	// }

	// .close-form img {
	// 	display:block;
		
	// }

	.close-form {

		height: 50px;
		width: 50px;
		position: absolute;
		z-index: 9;
		background: transparent;
		border: none;
	 	top: 25px;
		right: 25px;
	    border: none;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    line-height: 50px;
	    display: inline-block;
	    outline: 0;
	    cursor: pointer;
	    -webkit-transform-origin: center;
	    -ms-transform-origin: center;
	    transform-origin: center;
	    -webkit-transform: scale(.8);
	    -ms-transform: scale(.8);
	    transform: scale(.8);

	    &:hover {
	    	&:before {
	    		transform: translate3d(0,0,0) rotate(0deg);
	    	}
	    	&:after {
	    		transform: translate3d(0,0,0) rotate(0deg);
	    	}
	    }

	    &:before {
		    -webkit-transform: rotate(-45deg);
		    -ms-transform: rotate(-45deg);
		    transform:translate3d(0,0,0) rotate(-45deg);
		    content: '';
		    position: absolute;
		    top: 50%;
		    left: 50%;
		    margin-top: -5px;
   			margin-left: -28px;
		    display: block;
		    height: 8px;
		    width: 55px;
		    background-color: #fff;
		    -webkit-transition: all .35s ease-out;
		    -o-transition: all .35s ease-out;
		    transition: all .35s ease-out;
		}

		&:after {
			-webkit-transform: rotate(-135deg);
		    -ms-transform: rotate(-135deg);
		    transform: rotate(-135deg);
		    content: '';
		    position: absolute;
		    margin-top: -5px;
   			margin-left: -28px;
		    top: 50%;
		    left: 50%;
		    display: block;
		    height: 8px;
		    width: 55px;
		    background-color: #fff;
		    -webkit-transition: all .35s ease-out;
		    -o-transition: all .35s ease-out;
		    transition: all .35s ease-out;
		}
    }
	

	.wpcf7-form {

		margin-top:30px;

		.flex {
			
			display:flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.wpcf7-not-valid-tip {
				color: #ff6666;
			}

			label {
				color: #fff;
				font-weight: bold;
				letter-spacing: 2px;
				width: 48%;
				margin-bottom: 15px;

				&.full-width {
					width: 100%;
				}

				input,
				textarea {
					width: 100%;
					background-color: $header-color;
					border: none;
					margin-top: 2px;
					min-height: 32px;
					line-height: 26px;
					font-weight: 400;
					padding: 0 10px;

					&:focus {
						outline: none;
					}
				}

				textarea {
					height: 180px !important;
				}

				span {
					display:block;
				}
			}



			input[type=submit] {
				margin: 15px auto 0 auto;
				background-color: #fff;
				color: $bg-color;
				text-transform: uppercase;
				text-align:center;
				border: none;
				padding: 10px 24px;
				font-weight: bold;
				font-size: 18px;
				letter-spacing: 2px;
				transition: all .7s linear;

				&:hover{
					text-decoration: none !important;
					background-color: $header-color;
					color: #fff;
				}
			}

			.checkbox {
				margin-top:0px !important;
				margin-left:3px !important;

				.wpcf7-list-item {
					display: block !important;
					padding: 5px 0 !important;

				}
			}
		}		
	}
}