.product-entry {

	.btn-back-category {
		color: #fff;
		background-color: $header-color;
		padding: 10px 10px 10px 0;
		width: 100px;
		margin-bottom: 20px;
		margin-top: -20px;
		cursor: pointer;
		text-align: center;

		&:hover .fa {
			padding-left: 0px;
			padding-right: 10px;
		}
		
		.fa {
			padding: 0 5px;
			transition: .4s all linear;
		}

	}

	a.back-to-products {
		color: #fff;
		background-color: $header-color;
		padding: 10px 10px 10px 0;
		width: 150px;
		margin-bottom: 20px;
		margin-top: -20px;
		cursor: pointer;
		text-align: center;
		display: block;
		margin-left: 8px;

		&:hover .fa {
			padding-left: 0px;
			padding-right: 10px;
		}
		
		.fa {
			padding: 0 5px;
			transition: .4s all linear;
		}
	}

	#product_details_container {
		align-items: flex-start !important;


		#product_details {
			p {
				margin-bottom: 0 !important;
			}

			.shortdesc {
				border-bottom: none !important;
				opacity: 0 !important;
				transition: all .6s ease !important;
				text-align: center;

				&.show {
					opacity:1!important;
				}

				.order_btn {
					display:block;
					margin: 60px auto 20px;
					background-color: #fff;
					color: $bg-color;
					text-transform: uppercase;
					letter-spacing: 4px;
					padding: 5px 15px;
					max-width: 220px;
					text-align: center;
					font-weight: bold;
					font-size: 16px;
					cursor: pointer;
					transition: all .3s ease;

					p {
					}

					&:hover,
					&:active,
					{
						text-decoration: none !important;
						transform: scale(1.03);
						-webkit-font-smoothing: antialiased;	
						-webkit-box-shadow: 0px 7px 28px -9px rgba(0,0,0,0.75);
						-moz-box-shadow: 0px 7px 28px -9px rgba(0,0,0,0.75);
						box-shadow: 0px 7px 28px -9px rgba(0,0,0,0.75);
					}
				}
			}
		}
	}
}


.boxed .after-product-details {
	border: none !important;

	.ic_tab_content.active {
		background: $bg-color !important;


		.dataTables_wrapper {
			color: #000;

			.dataTables_length {
				label {
					color: #fff;
					margin-bottom: 10px;

					select {
						color: #000;
					}
				}
			}

			.dataTables_filter {
				display:none;
			}

			.dataTables_info {
				color: #fff;
				margin-top:15px;
				display:none;
			}

			.dataTables_paginate {
				margin-top:15px;

				.paginate_button {
					&.previous{
						text-shadow: none !important;
						color: #fff !important;
						text-decoration: none;

						&.disabled {
							color: #aaa !important;

							&:before {
								color: #aaa !important;
								content: '\000ab' !important;
								text-shadow: none !important;
							}
						}

						&:before {
							content: '\000ab' !important;
							text-shadow: none !important;
						}
					}

					&.next {
						text-shadow: none !important;
						color: #fff !important;
						text-decoration: none;
						margin-left: 40px;

						&.disabled {
							color: #aaa !important;

							&:after {
								color: #aaa !important;
								content: '\000bb' !important;
								text-shadow: none !important;
							}
						}

						&:after {
							content: '\000bb' !important;
							text-shadow: none !important;
						}
					}
				}
			}
		}
	}
}

.tablepress {

	tbody {
		color: $bg-color;

		tr:first-of-type {
		}

		.odd {
			td {
				background-color: #e6f4ff !important;
			}
		}		
	}


	.sorting {
		background-color: $header-color;
		color: #fff;

		&:hover {
			background-color: $header-color !important;
		}
	}

	.sorting_asc {
		background-color: $header-color !important;
		color: $bg-color;
	}

	.sorting_desc {
		background-color: $header-color !important;
		color: $bg-color;
	}
}

#product_search_form {
	float:right;
	display:none;
	z-index: 1;
	padding-bottom: 7px;
	max-width: 200px !important;
	margin-top: -45px;

	&.show {
		display: block;
	}

	&:after {
		display: block;
		clear:both;
	}

	.product-search-box {
		color: $bg-color;
		font-weight: bold;

		&::placeholder {
			color: #fff;
		} 

		&:active {
			outline: none;
		}
	}

	.product-search-submit {
		background-color: $header-color !important;

		&:active,
		&:foucs {
			outline: none;
			border: none;
		}
	}
}

.product-list {
	p {
		font-size: 22px;
		text-align: center;
		margin: 50px 0;
	}
}

.product_search_form {
	display:none !important;
}

.hidden-table {
	display: none;
}





	
