/*
========================
	Strona główna
========================
*/

@media all and (max-width: 1280px) {
	.reference {
		.row {
			.col-md-6 {
				padding-left: 60px;
			}
		}
	}
}

@media all and (max-width: 1200px) {
	.ecommerce-product-catalog {
		.al_archive {
			.product-name {
				font-size: 14px !important;
			}
		}
	}
}

@media all and (max-width: 1180px) {

	.footer-content {
		h2 {
			letter-spacing: 9px !important;
			line-height: 1.4 !important;
		}
	} 
}

@media all and (max-width: 1000px) {
	.boxed.responsive .after-product-details>div:last-child .ic_accordion_content_container {
		border: none;
	}

	.ic_accordion_content_container {
		border: none !important;
		padding: 0 !important;
		background: $bg-color !important;
	}

	.dataTables_length {
		label {
			color: #fff;
			margin-bottom: 10px;

			select {
				color: #000;
			}
		}
	}

	.dataTables_filter {
		display:none;
	}

	.dataTables_info {
		color: #fff;
		margin-top:15px;
		display: none;
	}

	.dataTables_paginate {
		margin-top:15px;

		.paginate_button {
			&.previous{
				text-shadow: none !important;
				color: #fff !important;
				text-decoration: none;

				&.disabled {
					color: #ccc !important;

					&:before {
						color: #ccc !important;
						content: '\000ab' !important;
						text-shadow: none !important;
					}
				}

				&:before {
					content: '\000ab' !important;
					text-shadow: none !important;
				}
			}

			&.next {
				text-shadow: none !important;
				color: #fff !important;
				text-decoration: none;
				margin-left: 40px;

				&.disabled {
					color: #ccc !important;

					&:after {
						color: #ccc !important;
						content: '\000bb' !important;
						text-shadow: none !important;
					}
				}

				&:after {
					content: '\000bb' !important;
					text-shadow: none !important;
				}
			}
		}
	}
}

@media all and (max-width: 991px) {

	#about {
		.column-l,
		.column-r {
			padding: 0 25px !important;
		}
	}

	.service-bg {
		.row {
			padding: 0 30px;

			.box {
				max-height: 90px;

				.pic {
					display: none !important;
				}

				h3{
					transition: transform .5s ease !important;
					opacity: 1 !important;

					&:hover {
						opacity: 1 !important;
						transform: scale(1.06) !important;
					}

					&:focus {
						opacity: 1 !important;
						transform: scale(1.06) !important;
					}
				}
			}
		}
	}

	.reference {
		.row {
			.col-md-6 {
				padding-left: 0;
				text-align:center;

			}
		}
	} 

	.footer-content {
		h2 {
			letter-spacing: 7px !important;
			font-size: 28px !important;
			margin-bottom: 50px !important;
		}
	}

	footer {
		.form-box {
			width: 750px !important; 
		}
	}

	.ecommerce-product-catalog {
		.per-row-4 {
			.modern-grid-element {
				width: 46.8%;
			}
		}
	}

	.product-subcategories {
		text-align: center !important;
	}
}

@media all and (max-width: 767px) {
	.service-bg {
		.box {
			h3{
				letter-spacing: 4px !important;
			}
		}
	}

	.footer-content {
		.phone,
		.mail,
		.address {
			text-align:center;
			padding-bottom: 25px;
		}
	} 

	footer {
		.form-box {
			width: 550px !important; 
		}
	}


	.page-id-71 {
		.offer-list {
			max-width: 420px;
			margin: 0 auto;
		}
	}
}

@media all and (max-width: 720px) {
	.header {
		h2 {
			font-size: 56px !important;
			letter-spacing: 18px !important;

			&:hover {
				letter-spacing: 24px !important;
			}
		} 
	}

	.sale-bg {
		.sale-content {
			h1 {
				font-size: 28px !important;
				letter-spacing: 12px;
			}
		}
	}

	.footer-content {
		h2 {
			letter-spacing: 4px !important;
			font-size: 24px !important;
		}

		.flex {
			.white-btn {
				font-size: 20px !important;
				letter-spacing: 6px !important;
			}
		}
	}

	.single-referencje {
		.refrence {
			h1 {
				letter-spacing: 4px;
			}
		}
	}

	.al_product .product-subcategories li {
		min-width: 75% !important;
	}
}

@media all and (max-width: 700px) {
	#product_details_container {
		margin-bottom: 50px;

		.product-image {
			img {
				margin-bottom: 30px;
			}
		}
	}

	.al_product {
		#breadcrumbs {
			display:none;
		}
	}

	#product_search_form {
		float: none !important;
		margin: 10px auto 35px !important;
	}
}

@media all and (max-width: 600px) {

h1 {
	letter-spacing: 12px !important;
	margin-bottom:75px !important;
}

h1:after {
	bottom: -20px !important;
}

	.header {

		nav {
			width: 100% !important;
			min-width: 1px !important;
			transition: all .8s ease;
			z-index:50;
		}
	}

	.service-bg {
		.row {
			.box {
				max-height: none !important;
				height: auto !important;
				padding: 30px 25px !important; 
			}
		}
	}

	.sale-bg {
		.sale-content {
			h1 {
				letter-spacing: 4px !important;
			}
		}
	}

	footer {
		.form-box {
			width: 100% !important; 
			height: 100vh !important;
			min-height: 100vh !important;
		}
	}

	.single-referencje {
		.refrence {
			h1 {
				letter-spacing: 4px !important;
				margin-bottom: 0 !important;
			}
		}
	}
}

@media all and (max-width: 500px) {
	.ecommerce-product-catalog {
		.per-row-4 {
			.modern-grid-element {
				width: 94%;
			}
		}
	}
}

@media all and (max-width: 480px) {
	
	.wpcf7-form {
		label {
			width: 100% !important;
		}

		input[type=submit] {
			width: 100%;
			padding: 10px 2px !important;
		}
	}
}

@media all and (max-width: 420px) {

	.reference {
		p {
			padding: 15px 25px !important;
			line-height: 1;
		}
	}

	.sale-content {
		h1 {
			max-width: 280px !important;
			line-height: 1.3;
		}
	}

	.footer-content {
		.white-btn {
			max-width: 280px !important;
		}
	}

	.single-referencje {
		.refrence {
			h1 {
				font-size: 30px;
				letter-spacing: 2px;
				word-break: break-all;
			}
		}
	}
}

@media all and (max-width: 380px) {
	.single-referencje {
		.references-list {
			p {
				width: 100% !important;
			}
		}
	}
}

@media all and (max-width: 360px) {
	h1 {
		letter-spacing: 4px !important;
	}

	.header {
		h2 {
			font-size: 48px !important;
			letter-spacing: 14px !important;

			&:hover {
				letter-spacing: 18px !important;
			}
		} 
	}

	.service-bg {
		#service {
			height: 50px !important;
		}
	}

	.wpcf7-form {

		input[type=submit] {
			letter-spacing: 0px !important;
		}
	}
}

@media (max-height: 435px) {
	nav {
		min-height: 320px;
		li {
			padding: 4px 0 !important;
			letter-spacing: 5px !important;
		}
	}
}