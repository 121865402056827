body {
    background: $bg-color;
    color: #fff;
    font-family: 'Jura-Light', Fallback, sans-serif;

    .container-fluid {
		padding:0;

		.header-bg {
			width: 100%;
			height:110px;
			background-color: $header-color;
			margin-bottom: 30px;
		
			.header {
				width:100%;
				height: 110px;
				position:absolute;
				top:0;
				left:0;
				z-index: 50;

				&.hide-header {
					position:static;
				}

				.EN {
				    color: #fff;
				    position: absolute;
				    top: 26px;
				    right: 90px;
				    font-size: 18px;
				    letter-spacing: 1px;
				}


				.brand {
					position:absolute;
					top:30px;
					left:30px;
					width: 130px;		
				}

				.nav_bar {
					width:100%;
					border-top: 5px solid #fff;
					padding-top: 30px;
					position: fixed;
					top:0;

					.top{
						width: 100%;
		
						.bars img{
							position:fixed;
							right: 30px;
							top:30px;
							cursor:pointer;
							width:40px;
						}
					}
				}

				nav {
					display: flex;
					justify-content: center;
					align-items: center;
					position: fixed;
					top: 5px;
					bottom: 0px;
					width: 40%;
					min-width: 315px;
					background-color: $bg-color;
					transform: translate3d(-100%, 0, 0);
					transition: all .8s cubic-bezier(0.38,-0.02, 0, 1.13);
					z-index:50;
					overflow-y: auto;

					&.show-nav {
						transform: translate3d(0, 0, 0);
					}

					&::-webkit-scrollbar {
					    width: 8px;
					}

					&::-webkit-scrollbar-track {
					    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
					    border-radius: 4px;
					}

					&::-webkit-scrollbar-thumb {
					    border-radius: 4px;
					    background-color: $header-color;
					}
					
					.brand {
						position:absolute;
						top:30px;
						left:30px;

						img {
							width: 130px;
						}
						
					}

					.close-nav {
						height: 50px;
						width: 50px;
						position: absolute;
						z-index: 9;
						background: transparent;
						border: none;
					 	top: 25px;
						right: 25px;
					    border: none;
					    -webkit-box-sizing: border-box;
					    box-sizing: border-box;
					    line-height: 50px;
					    display: inline-block;
					    outline: 0;
					    cursor: pointer;
					    -webkit-transform-origin: center;
					    -ms-transform-origin: center;
					    transform-origin: center;
					    -webkit-transform: scale(.8);
					    -ms-transform: scale(.8);
					    transform: scale(.8);

					    &:hover {
					    	&:before {
					    		transform: translate3d(0,0,0) rotate(0deg);
					    	}
					    	&:after {
					    		transform: translate3d(0,0,0) rotate(0deg);
					    	}
					    }

					    &:before {
						    -webkit-transform: rotate(-45deg);
						    -ms-transform: rotate(-45deg);
						    transform:translate3d(0,0,0) rotate(-45deg);
						    content: '';
						    position: absolute;
						    top: 50%;
						    left: 50%;
						    margin-top: -5px;
				   			margin-left: -28px;
						    display: block;
						    height: 8px;
						    width: 55px;
						    background-color: #fff;
						    -webkit-transition: all .35s ease-out;
						    -o-transition: all .35s ease-out;
						    transition: all .35s ease-out;
						}

						&:after {
							-webkit-transform: rotate(-135deg);
						    -ms-transform: rotate(-135deg);
						    transform: rotate(-135deg);
						    content: '';
						    position: absolute;
						    margin-top: -5px;
				   			margin-left: -28px;
						    top: 50%;
						    left: 50%;
						    display: block;
						    height: 8px;
						    width: 55px;
						    background-color: #fff;
						    -webkit-transition: all .35s ease-out;
						    -o-transition: all .35s ease-out;
						    transition: all .35s ease-out;
						}
				    }


					ul {
						margin-top: 50px;
						margin-bottom: 0px;
						margin-left: 0px;

						li{

							font-size: 20px;
							text-transform: uppercase;
							padding: 14px 0;
							letter-spacing: 8px;
							line-height: 1;

							&:hover {
								a {
									transform: translate3d(50px, 0, 0);
									text-decoration: none !important;

									&:before {
										opacity:1;
									}

									&:hover {
										text-decoration: none !important;
									}

									&:focus {
										text-decoration: none !important;
									}

									&:active {
										text-decoration: none !important;
									}

									&:link {
										text-decoration: none !important;
									}
		

								}		
							}

							a {
								color: white;
								display: inline-block;
								position: relative;
								transition: all .8s;

								&:before {
									content: '';
									position:absolute;
									right: 100%;
									height: 12px;
									margin-right:25px;
									border-bottom: 3px #fff solid;
									width: 25px;
									opacity:0;
									transition: all .8s;
								}
							}
						}
					}

					.lang {
						position: absolute;
						bottom: 0;
						left: 50px;
						text-transform: uppercase;
						letter-spacing: 5px;
						font-size: 14px;
					}
				} 


				h2 {
					font-family: 'Jura-Light', Fallback, sans-serif;
					font-size: 64px;
					margin:auto;
					letter-spacing: 22px;
					text-transform: uppercase;
					display:block;
					text-align:center;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 100%;
					cursor: pointer;
					transition: all .8s ease;

					&:hover {
						letter-spacing: 28px;
					}

					&.hide-h2 {
						font-size:1px;
						opacity: 0;
					}
				}
			}
		}
	}

	.references {

		h3 {
			text-align: center;
			font-size: 24px;
			margin-bottom: 25px;
			letter-spacing: 2px;
			font-weight: 400;
		}

		.references-list {
			margin-bottom: 120px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			padding: 0 30px;

			p  {
				font-size: 16px;
				background-color: $header-color;
				margin: 7px;
				padding: 2px 7px;
				color: #fff;
				text-transform: uppercase;
				font-weight: bold;
				transition: all .7s ease;
				text-align: center;

				&:hover {
					background-color: #fff;
					color: $bg-color;

					a {
						color: $bg-color;
					}
				}

				a {
					color: #fff;

					&:hover {
						text-decoration: none !important;
					}

					&:active {
						text-decoration: none !important;
					}
				}

				ul {
					display:none;
				}
			}
		}
	}


	.refrence {
			
			margin-bottom: 175px;

			h1 {
				margin-bottom: 40px;
				text-align: center;
				letter-spacing: 8px;
			}

			p {
				margin-bottom: 25px;
				text-align: center;
			}

			.gm-style .gm-style-iw {
				h3 {
					color: #000;
					font-weight: bold;
					text-align: left;
				}

				p {
					color: #000;
					margin-bottom: 0px;
					padding:2px 0 0 0;
					text-align: left;
				}
			}
		}
}
