.page-id-71 {

	h1 {
		letter-spacing: 10px;
	}

	h2 {
		text-align: center;
		letter-spacing: 2px;
		font-size: 26px;
		margin-bottom: 50px;
		line-height: 1.5;
	}

	ul {
		margin-left: 60px;
		margin-bottom: 150px;

		li {
			padding: 10px 30px 10px 0px;
			font-size: 16px;
			line-height: 1.2;

			&:before {
				content: '';
				position:absolute;
				right: 100%;
				height: 12px;
				margin-right: 5px;
				border-bottom: 3px #fff solid;
				width: 25px;
				opacity:1;
			}
		}
	}

	.owl-theme {
		.owl-nav {
			background-color: $bg-color;
		}

		.owl-nav [class*=owl-] {
			margin: 10px 35px !important;
			background-color: transparent !important;
		}
	}

	.owl-carousel {
		margin-bottom: 170px;

		.owl-item{
			height: 220px;
			display:flex;
			justify-content: center;
		}

		.item {
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 80% !important;
			}

		}
		.owl-stage-outer {
			background-color: #fff;
		}
	}
}