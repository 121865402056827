.al_product {
	margin-bottom: 150px;

	.product-sort-bar {
		display:none !important;
	}

	#breadcrumbs {
		
		margin-top: -100px;
		margin-bottom: 80px;

		a {
			color: #fff;

			&:hover {
				text-decoration: underline;
				color: #fff;
			}
		}
	}

	.catalog-header {
		text-align: center;
		font-size: 32px;
		margin-bottom: 50px;
		letter-spacing: 2px;
		padding-top: 60px;
	}

	.product-subcategories {
		margin-bottom: 50px;

		li {
			font-size: 16px;
			color: #fff;
			text-transform: uppercase;
			font-weight: bold;
			transition: all .4s ease;
			text-align:center;
			width: 33%;
			min-height: 65px;
			position: relative;
			margin: 15px 0;


			&:hover {
				background-color: #fff;
				color: $bg-color;

				a {
					color: $bg-color;
				}
			}

			a {
				color: #fff;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
				padding: 7px;

				&:hover {
					text-decoration: none !important;
				}

				&:active {
					text-decoration: none !important;
				}

				&:link {
					text-decoration: none !important;
				}
			}

			ul {
				display:none;
			}
		}
	}

	.al_archive {

		box-shadow: none !important;
		border-radius: 2px;

		a:hover {
			text-decoration: none !important;
		}

		h3.product-name {
			z-index:0 !important;
			background-color: rgba(41,91,133,.8) !important;
			max-width: 100% !important;
			width: 100% !important;
			box-shadow: none !important;
			padding: 5px 8px !important;
			position: absolute !important;
			top: 75% !important;
			bottom:0 !important;
			left:0 !important;
			color: #fff !important;
			text-align: center !important;
		}
	}

	.product-archive-nav {
		li {
			a {
				background-color: $header-color !important;
				border-radius: 0px !important;
			}
		&.active {
			a {
				background-color: #fff !important;
				color: $bg-color;
				border-radius: 0px !important;
			}
			
			}
		}
	}
}


.ecommerce-product-catalog {
	.fluid-container {
		&.bg-product-img {
			background:linear-gradient(0deg,rgba(0,56,99,1),rgba(0,56,99,.8),rgba(0,56,99,.8),rgba(0,56,99,1)),url('../img/sale-bg.jpg');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			margin-bottom: 150px;
			margin-top: 50px;
		}

		&.type-al_product {
			background:inherit;
		}
	}
}
