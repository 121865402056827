.not-found,
.search-results {
	margin-bottom: 150px;
	
	.page-header {
		border-bottom: none;
	}

	.page-content {
		text-align: center;

		p {
			font-size: 22px;

			a {
				color: #8ab7db;
				
				&:hover {
					text-decoration: none;
					color: #fff;
				}
			}
		}

		.search-form {
			margin-top: 50px;

			.screen-reader-text {
			    display: block;
			    font-size: 20px;
			    margin-bottom: 15px;
			}

			.search-field {
				background-color: $header-color;
				border: none;
				margin-top: 2px;
				min-height: 32px;
				line-height: 26px;
				font-weight: 400;
				padding: 0 10px;

				&::placeholder {
					visibility: hidden;
					display:none;
				}

				&:focus {
					outline: none;
				}
			}

			input[type=submit] {
				display:block;
				margin: 15px auto 0 auto;
				background-color: #fff;
				color: $bg-color;
				text-transform: uppercase;
				text-align:center;
				border: none;
				padding: 10px 24px;
				font-weight: bold;
				font-size: 18px;
				letter-spacing: 2px;
				transition: all .7s linear;

				&:hover{
					text-decoration: none !important;
					background-color: $header-color;
					color: #fff;
				}

				&:focus {
					outline: none;
				}
			}
		}
	}
}


.search-results {

	.content-area {
		margin-bottom: 120px;

		article {
			margin-bottom:50px;

			h2 {
				text-align:center;
				font-size:22px;

				a {
					color: #8ab7db;
					
					&:hover {
						text-decoration: none;
						color: #fff;
					}
				}
			}
		}

	}

}
