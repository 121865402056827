@import 'reset';
@import 'var';
@import 'media';
@import 'basics';
@import 'reference';
@import 'products';
@import 'product';
@import 'forms';
@import 'oferta';
@import 'page-child';
@import '404';



@font-face {
  font-family: 'Jura';
  src: url('../font/jura/JuraMedium.eot'); 
  src: url('../font/jura/JuraMedium.eot?#iefix') format('embedded-opentype'), 
       url('../font/jura/JuraMedium.woff2') format('woff2'), 
       url('../font/jura/JuraMedium.woff') format('woff'), 
       url('../font/jura/JuraMedium.ttf')  format('truetype'), 
}

@font-face {
  font-family: 'Jura-Light';
  src: url('../font/jura/JuraLight.eot'); 
  src: url('../font/jura/JuraLight.eot?#iefix') format('embedded-opentype'), 
       url('../font/jura/JuraLight.woff2') format('woff2'), 
       url('../font/jura/JuraLight.woff') format('woff'), 
       url('../font/jura/JuraLight.ttf')  format('truetype'), 
}

@font-face {
  font-family: 'Jura-Bold';
  src: url('../font/jura/JuraDemiBold.eot'); 
  src: url('../font/jura/JuraDemiBold.eot?#iefix') format('embedded-opentype'), 
       url('../font/jura/JuraDemiBold.woff2') format('woff2'), 
       url('../font/jura/JuraDemiBold.woff') format('woff'), 
       url('../font/jura/JuraDemiBold.ttf')  format('truetype'), 
}


.aligncenter {
	display: block !important;
	margin: 0 auto !important;
}

.alignright {
	display: block !important;
	margin: 0 0 0 auto !important;
}

body {
    background: $bg-color;
    color: #fff;
    font-family: 'Jura-Light', Fallback, sans-serif;

    &.home{

    	overflow-x: hidden;

    	.container-fluid {
			padding:0;

			.header-bg {
				width: 100%;
				height:100vh;
				min-height: 500px;
				background-size: contain;
				background-repeat: none;
				background-position: center;
				margin-bottom: 0px;

				.sow-google-map-canvas {
	        		height: 100vh !important;
	        		min-height: 500px;
	      		}

				
				.header {
					width:100%;
					height:100vh;
					min-height: 500px;
					position:absolute;
					top:0;
					left:0;
					z-index: 50;

					&.hide-header {
						position:static;
					}

					.EN {
					    color: #fff;
					    position: absolute;
					    top: 26px;
					    right: 90px;
					    font-size: 18px;
					    letter-spacing: 1px;
					}

					.brand {
						position:absolute;
						top:30px;
						left:30px;
						width: 130px;		
					}

					.nav_bar {
						width:100%;
						border-top: 5px solid #fff;
						padding-top: 30px;
						position: fixed;
						top:0;

						.top{
							width: 100%;
			
							.bars img{
								position:fixed;
								right: 30px;
								top:30px;
								cursor:pointer;
								width:40px;
							}
						}
					}

					nav {
						display: flex;
						justify-content: center;
						align-items: center;
						position: fixed;
						top: 5px;
						bottom: 0px;
						width: 40%;
						min-width: 315px;
						background-color: $bg-color;
						transform: translate3d(-100%, 0, 0);
						transition: all .8s cubic-bezier(0.38,-0.02, 0, 1.13);
						z-index:50;
						overflow-y: auto;

						&.show-nav {
							transform: translate3d(0, 0, 0);
						}

						&::-webkit-scrollbar {
						    width: 8px;
						}

						&::-webkit-scrollbar-track {
						    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
						    border-radius: 4px;
						}

						&::-webkit-scrollbar-thumb {
						    border-radius: 4px;
						    background-color: $header-color;
						}
						
						.brand {
							position:absolute;
							top:30px;
							left:30px;

							img {
								width: 130px;
							}
							
						}

						.close-nav {
							height: 50px;
							width: 50px;
							position: absolute;
							z-index: 9;
							background: transparent;
							border: none;
						 	top: 25px;
							right: 25px;
						    border: none;
						    -webkit-box-sizing: border-box;
						    box-sizing: border-box;
						    line-height: 50px;
						    display: inline-block;
						    outline: 0;
						    cursor: pointer;
						    -webkit-transform-origin: center;
						    -ms-transform-origin: center;
						    transform-origin: center;
						    -webkit-transform: scale(.8);
						    -ms-transform: scale(.8);
						    transform: scale(.8);

						    &:hover {
						    	&:before {
						    		transform: translate3d(0,0,0) rotate(0deg);
						    	}
						    	&:after {
						    		transform: translate3d(0,0,0) rotate(0deg);
						    	}
						    }

						    &:before {
							    -webkit-transform: rotate(-45deg);
							    -ms-transform: rotate(-45deg);
							    transform:translate3d(0,0,0) rotate(-45deg);
							    content: '';
							    position: absolute;
							    top: 50%;
							    left: 50%;
							    margin-top: -5px;
					   			margin-left: -28px;
							    display: block;
							    height: 8px;
							    width: 55px;
							    background-color: #fff;
							    -webkit-transition: all .35s ease-out;
							    -o-transition: all .35s ease-out;
							    transition: all .35s ease-out;
							}

							&:after {
								-webkit-transform: rotate(-135deg);
							    -ms-transform: rotate(-135deg);
							    transform: rotate(-135deg);
							    content: '';
							    position: absolute;
							    margin-top: -5px;
					   			margin-left: -28px;
							    top: 50%;
							    left: 50%;
							    display: block;
							    height: 8px;
							    width: 55px;
							    background-color: #fff;
							    -webkit-transition: all .35s ease-out;
							    -o-transition: all .35s ease-out;
							    transition: all .35s ease-out;
							}
					    }

						ul {
							margin-top: 50px;
							margin-bottom: 0px;
							margin-left: 0px;

							li{

								font-size: 20px;
								text-transform: uppercase;
								padding: 14px 0;
								letter-spacing: 8px;
								line-height: 1;

								&:hover {
									a {
										transform: translate3d(50px, 0, 0);
										text-decoration: none !important;

										&:before {
											opacity:1;
										}

										&:hover {
											text-decoration: none !important;
										}

										&:focus {
											text-decoration: none !important;
										}

										&:active {
											text-decoration: none !important;
										}

										&:link {
											text-decoration: none !important;
										}
			

									}		
								}

								a {
									color: white;
									display: inline-block;
									position: relative;
									transition: all .8s;

									&:before {
										content: '';
										position:absolute;
										right: 100%;
										height: 12px;
										margin-right:25px;
										border-bottom: 3px #fff solid;
										width: 25px;
										opacity:0;
										transition: all .8s;
									}
								}
							}
						}

						.lang {
							position: absolute;
							bottom: 0;
							left: 50px;
							text-transform: uppercase;
							letter-spacing: 5px;
							font-size: 14px;
						}
					}

					h2 {
						font-family: 'Jura-Light', Fallback, sans-serif;
						font-size: 64px;
						margin:auto;
						letter-spacing: 22px;
						text-transform: uppercase;
						display:block;
						text-align:center;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 100%;
						cursor: pointer;
						transition: all .8s ease;

						&:hover {
							letter-spacing: 28px;
						}

						&.hide-h2 {
							font-size:1px;
							opacity: 0;
						}
					}

					@keyframes MoveUpDown {
						0% {
					    	bottom: 10px; 
						}
					  	50% {
					    	bottom: 40px; 
						}
					  	100% {
					    	bottom: 10px; 
					    } 
					}

					.scroll-down {
						position: absolute;
						bottom: 30px;
						left: 50%;
						transform: translateX(-50%); 
						animation: MoveUpDown 2.8s linear infinite;
						cursor:pointer;

						img {
							width: 42px;
							height: auto;
						}
					}

					.facebook {
						position: absolute;
						bottom: 30px;
						right: 35px;

						.fa {
							font-size: 36px;
							color: #fff;
							transition: .2s all linear;

							&:hover {
								color: #ccc;
							}
						}
					}

					.youtube {
						position: absolute;
						bottom: 30px;
						right: 80px;

						.fa {
							font-size: 36px;
							color: #fff;
							transition: .2s all linear;

							&:hover {
								color: #ccc;
							}
						}
					}
				}

				.header-overlay{
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:100vh;
					min-height: 500px;
					background-color:rgba(11, 60, 107,.7);
					transition: 1s all ease;

					&.hide-overlay {
						transform: translate3d(0, -100%, 0);
					}
				}
			}
		}
    }
	
	

	.container {

		#about {

			padding-top: 150px;

			.column-l {
				padding-right:65px;
			}

			.column-r {
				padding-left:65px;
			}

			.column-m p {
				padding-top:50px;
				max-width:500px;
				margin:0 auto;
			}

			p {
				text-align:center;
				font-size:18px;
			}
		}	
	}

	.service-bg {
		min-height: 1191px;
		height: auto;
		background-image: url("../img/service-bg.png");
		background-repeat: none;
		background-size: cover;
		background-position: center;
		padding-top:150px;
		margin-bottom: 150px;

		#service {
			height: 100px;
		}

		.box {
			height:280px;
			position: relative;

			&:hover {
				.pic {
					img {
						transform: scale(1);
						opacity: 1;
						box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.6);
						cursor: pointer;
					}
				}

				h3 {
					opacity: 0;
					z-index: 0;
				}	
			}

			.pic {
				position: absolute;
				top:0;
				left:0;
				padding:12px;

				
				img {
					border: 10px #fff solid;
					max-width:426px;
					width:100%;
					height:auto;
					transform: scale(0);
					opacity: 0;
					transition: opacity 1.6s ease, transform .8s ease, box-shadow .8s ease .8s;
				}
			}

			h3 {
				cursor: pointer;
				text-transform: uppercase;
				font-size:20px;
				letter-spacing: 7px;
				text-align:center;
				width:100%;
				position:relative;
				top: 35%;
				line-height:1.3;
				display: block;
				z-index:1;
				transition: all .5s ease .2s;

				a {
					color: #fff;
					text-decoration: none;

					&:hover {
						text-decoration: none;
					}
				}
				
				// &:hover{

				// 	opacity: 0;

				// 	+ .pic img{
				// 		transform: scale(1);
				// 		opacity: 1;
				// 		box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.6);
				// 	}
				// } 
			}
		}
	}

	#reference {
		padding-top: 100px;
    	margin-top: -100px;
	}

	.reference {
		margin-bottom: 150px;
		p {
			padding:6px 35px;

			&:hover {
				a {
					transform: translate3d(50px, 0, 0);
					text-decoration: none !important;

					&:before {
						opacity:1;
					}
				}		
			}

			a {
				color: white;
				display: inline-block;
				position: relative;
				transition: all .8s;

				&:before {
					content: '';
					position:absolute;
					right: 100%;
					height: 12px;
					margin-right:25px;
					border-bottom: 3px #fff solid;
					width: 25px;
					opacity:0;
					transition: all .8s;
				}	
			}
		}
	}

	.sale-bg {
		height: 450px;
		background:linear-gradient(0deg,rgba(0,56,99,0.7),rgba(0,56,99,0.7)),url('../img/sale-bg.jpg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		background-attachment: fixed; 
		margin-bottom: 150px;


		.sale-content {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 450px;

			h1 {
				font-family: 'Jura', Fallback, sans-serif;
				color: $bg-color;
				background-color: #fff;
				padding: 20px 40px;
				display: block;
				margin-top:100px;
				transition: all .3s ease;

				&:hover,
				&:active,
				{
					text-decoration: none !important;
					transform: scale(1.03);	
					-webkit-box-shadow: 0px 7px 28px -9px rgba(0,0,0,0.75);
					-moz-box-shadow: 0px 7px 28px -9px rgba(0,0,0,0.75);
					box-shadow: 0px 7px 28px -9px rgba(0,0,0,0.75);
				}

				&:after {
					display:none;
				}

				a {
					color: $bg-color;

					&:hover,
					&:visited,
					&:active {
						color: $bg-color;
						text-decoration: none !important;
					}
				}
			}
		}
	}

	.footer-content {
		h2{
			text-align:center;
			font-size: 32px;
			text-transform: uppercase;
			letter-spacing: 15px;
			margin-bottom: 75px;
			line-height: 1.3;
		}

		.address,
		.phone,
		.mail {
			width: 130px;
			margin: 0 auto;
			position:relative;
		}

		.address:before{
			font-family: FontAwesome;
    		content: "\f0ac";
    		position:absolute;
			left:-40px;
			top: 15px;
			font-size: 20px;
		}

		.phone:before{
			font-family: FontAwesome;
    		content: "\f095";
    		position:absolute;
			left:-40px;
			top: 15px;
			font-size: 20px;
		}

		.mail:before{
			font-family: FontAwesome;
    		content: "\f0e0";
    		position:absolute;
			left:-40px;
			top: 15px;
			font-size: 20px;
		}

		.white-btn {
			font-family: 'Jura', Fallback, sans-serif;
			letter-spacing: 8px;
			background: #fff;
			padding: 10px 20px;
			color: $bg-color;
			text-transform: uppercase; 
			display:block;
			text-align:center;
			margin:70px auto;
			font-size: 24px;
			transition: all .3s ease;

			&:hover,
			&:active,
			{
				text-decoration: none !important;
				transform: scale(1.03);	
				-webkit-box-shadow: 0px 7px 28px -9px rgba(0,0,0,0.75);
				-moz-box-shadow: 0px 7px 28px -9px rgba(0,0,0,0.75);
				box-shadow: 0px 7px 28px -9px rgba(0,0,0,0.75);
			}

		}

		.fb {
			color: #fff !important;
			text-decoration: none!important;

			&:hover {
				text-decoration: none!important;
				color: $bg-color!important;
			}

			.facebook {
				font-size: 36px;
				margin:30px;
				background-color: $bg-color;
				width: 70px;
				height: 70px;
				margin: 0 auto;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 35px;
				transition: all .3s ease;
				cursor: pointer;
				color: #fff;

				&:hover {
					background-color: #fff;
					color: $bg-color;
				}
			}
		}
	}

	.footer-map {
			height: 600px;
			margin-top: 100px;
			position: relative;

			.sow-google-map-canvas {
        		height: 600px !important;
      		}

			.footer-overlay {
				position:absolute;
				top:0;
				left:0;
				height:600px;
				width:100%;
				background-color:rgba(0, 0, 0,.4);
				transition: 1s all ease;
				cursor:pointer;

				&.hide-foooter-overlay {
					height: 0;
				}
			}

			.web-info {
				position: absolute;
				z-index: 1;
				color: white;
				text-align:center;
				bottom: 30px;
				left: 50%;
				transform: translateX(-50%);

				p {
					padding-top:0px;

					a {
						color: #fff;

						&:visited {

							color: #fff;
						}
					}
				}
			}
		}
	}


.cookie-notice-container {
	#cn-notice-text {
		color: #000 !important;
		font-size: 15px !important;
	}

	.cn-set-cookie.button.wp-default {
		background-color: red;
	}

	.button {
		padding: 4px 15px;
		border: solid $bg-color 1px;
		font-size: 15px;
		color: $bg-color;
		text-transform: upperacase;
	}
}

// Wordpress Classes
img.alignleft {
	float:left;
	padding: 20px;

	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

img.aligncenter {
	display: block;
	margin: 0 auto;
	padding: 20px;
}

img.alignright {
	float: right;
	padding: 20px;

	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

img.alignnone {
	display: block;
	margin-left: 0px;
}

.gallery {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;

	.gallery-item {
		padding: 20px;
	}

}

#pwbox-2944 {
	color: black;
}

.post-password-form {
	input {
		color: black;
	}
}