@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  color: #000000;
  text-decoration: none;
  outline: 0; }
  a:active {
    color: #000000;
    text-decoration: none;
    outline: 0; }
  a:focus {
    color: #000000;
    text-decoration: none;
    outline: 0; }
  a:visited {
    color: #000000;
    text-decoration: none;
    outline: 0; }
  a:hover {
    color: #000000;
    text-decoration: none;
    outline: 0; }

/*
========================
	Strona główna
========================
*/
@media all and (max-width: 1280px) {
  .reference .row .col-md-6 {
    padding-left: 60px; } }

@media all and (max-width: 1200px) {
  .ecommerce-product-catalog .al_archive .product-name {
    font-size: 14px !important; } }

@media all and (max-width: 1180px) {
  .footer-content h2 {
    letter-spacing: 9px !important;
    line-height: 1.4 !important; } }

@media all and (max-width: 1000px) {
  .boxed.responsive .after-product-details > div:last-child .ic_accordion_content_container {
    border: none; }
  .ic_accordion_content_container {
    border: none !important;
    padding: 0 !important;
    background: #003863 !important; }
  .dataTables_length label {
    color: #fff;
    margin-bottom: 10px; }
    .dataTables_length label select {
      color: #000; }
  .dataTables_filter {
    display: none; }
  .dataTables_info {
    color: #fff;
    margin-top: 15px;
    display: none; }
  .dataTables_paginate {
    margin-top: 15px; }
    .dataTables_paginate .paginate_button.previous {
      text-shadow: none !important;
      color: #fff !important;
      text-decoration: none; }
      .dataTables_paginate .paginate_button.previous.disabled {
        color: #ccc !important; }
        .dataTables_paginate .paginate_button.previous.disabled:before {
          color: #ccc !important;
          content: '\000ab' !important;
          text-shadow: none !important; }
      .dataTables_paginate .paginate_button.previous:before {
        content: '\000ab' !important;
        text-shadow: none !important; }
    .dataTables_paginate .paginate_button.next {
      text-shadow: none !important;
      color: #fff !important;
      text-decoration: none;
      margin-left: 40px; }
      .dataTables_paginate .paginate_button.next.disabled {
        color: #ccc !important; }
        .dataTables_paginate .paginate_button.next.disabled:after {
          color: #ccc !important;
          content: '\000bb' !important;
          text-shadow: none !important; }
      .dataTables_paginate .paginate_button.next:after {
        content: '\000bb' !important;
        text-shadow: none !important; } }

@media all and (max-width: 991px) {
  #about .column-l,
  #about .column-r {
    padding: 0 25px !important; }
  .service-bg .row {
    padding: 0 30px; }
    .service-bg .row .box {
      max-height: 90px; }
      .service-bg .row .box .pic {
        display: none !important; }
      .service-bg .row .box h3 {
        transition: transform .5s ease !important;
        opacity: 1 !important; }
        .service-bg .row .box h3:hover {
          opacity: 1 !important;
          transform: scale(1.06) !important; }
        .service-bg .row .box h3:focus {
          opacity: 1 !important;
          transform: scale(1.06) !important; }
  .reference .row .col-md-6 {
    padding-left: 0;
    text-align: center; }
  .footer-content h2 {
    letter-spacing: 7px !important;
    font-size: 28px !important;
    margin-bottom: 50px !important; }
  footer .form-box {
    width: 750px !important; }
  .ecommerce-product-catalog .per-row-4 .modern-grid-element {
    width: 46.8%; }
  .product-subcategories {
    text-align: center !important; } }

@media all and (max-width: 767px) {
  .service-bg .box h3 {
    letter-spacing: 4px !important; }
  .footer-content .phone,
  .footer-content .mail,
  .footer-content .address {
    text-align: center;
    padding-bottom: 25px; }
  footer .form-box {
    width: 550px !important; }
  .page-id-71 .offer-list {
    max-width: 420px;
    margin: 0 auto; } }

@media all and (max-width: 720px) {
  .header h2 {
    font-size: 56px !important;
    letter-spacing: 18px !important; }
    .header h2:hover {
      letter-spacing: 24px !important; }
  .sale-bg .sale-content h1 {
    font-size: 28px !important;
    letter-spacing: 12px; }
  .footer-content h2 {
    letter-spacing: 4px !important;
    font-size: 24px !important; }
  .footer-content .flex .white-btn {
    font-size: 20px !important;
    letter-spacing: 6px !important; }
  .single-referencje .refrence h1 {
    letter-spacing: 4px; }
  .al_product .product-subcategories li {
    min-width: 75% !important; } }

@media all and (max-width: 700px) {
  #product_details_container {
    margin-bottom: 50px; }
    #product_details_container .product-image img {
      margin-bottom: 30px; }
  .al_product #breadcrumbs {
    display: none; }
  #product_search_form {
    float: none !important;
    margin: 10px auto 35px !important; } }

@media all and (max-width: 600px) {
  h1 {
    letter-spacing: 12px !important;
    margin-bottom: 75px !important; }
  h1:after {
    bottom: -20px !important; }
  .header nav {
    width: 100% !important;
    min-width: 1px !important;
    transition: all .8s ease;
    z-index: 50; }
  .service-bg .row .box {
    max-height: none !important;
    height: auto !important;
    padding: 30px 25px !important; }
  .sale-bg .sale-content h1 {
    letter-spacing: 4px !important; }
  footer .form-box {
    width: 100% !important;
    height: 100vh !important;
    min-height: 100vh !important; }
  .single-referencje .refrence h1 {
    letter-spacing: 4px !important;
    margin-bottom: 0 !important; } }

@media all and (max-width: 500px) {
  .ecommerce-product-catalog .per-row-4 .modern-grid-element {
    width: 94%; } }

@media all and (max-width: 480px) {
  .wpcf7-form label {
    width: 100% !important; }
  .wpcf7-form input[type=submit] {
    width: 100%;
    padding: 10px 2px !important; } }

@media all and (max-width: 420px) {
  .reference p {
    padding: 15px 25px !important;
    line-height: 1; }
  .sale-content h1 {
    max-width: 280px !important;
    line-height: 1.3; }
  .footer-content .white-btn {
    max-width: 280px !important; }
  .single-referencje .refrence h1 {
    font-size: 30px;
    letter-spacing: 2px;
    word-break: break-all; } }

@media all and (max-width: 380px) {
  .single-referencje .references-list p {
    width: 100% !important; } }

@media all and (max-width: 360px) {
  h1 {
    letter-spacing: 4px !important; }
  .header h2 {
    font-size: 48px !important;
    letter-spacing: 14px !important; }
    .header h2:hover {
      letter-spacing: 18px !important; }
  .service-bg #service {
    height: 50px !important; }
  .wpcf7-form input[type=submit] {
    letter-spacing: 0px !important; } }

@media (max-height: 435px) {
  nav {
    min-height: 320px; }
    nav li {
      padding: 4px 0 !important;
      letter-spacing: 5px !important; } }

p {
  line-height: 1.5;
  padding: 12px 0 12px; }

h1 {
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 16px;
  position: relative;
  margin-bottom: 100px; }

h1:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -30px;
  height: 4px;
  width: 70px;
  border-bottom: 4px solid #fff; }

.flex {
  display: flex; }

.strong {
  font-weight: bold; }

.nowrap {
  white-space: nowrap; }

.margin-bottom-150 {
  margin-bottom: 150px; }

.margin-bottom-100 {
  margin-bottom: 100px; }

.margin-bottom-50 {
  margin-bottom: 50px; }

.margin-top-150 {
  margin-top: 150px; }

.margin-top-100 {
  margin-top: 100px; }

.margin-top-50 {
  margin-top: 50px; }

body {
  background: #003863;
  color: #fff;
  font-family: 'Jura-Light', Fallback, sans-serif; }
  body .container-fluid {
    padding: 0; }
    body .container-fluid .header-bg {
      width: 100%;
      height: 110px;
      background-color: #295B85;
      margin-bottom: 30px; }
      body .container-fluid .header-bg .header {
        width: 100%;
        height: 110px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 50; }
        body .container-fluid .header-bg .header.hide-header {
          position: static; }
        body .container-fluid .header-bg .header .EN {
          color: #fff;
          position: absolute;
          top: 26px;
          right: 90px;
          font-size: 18px;
          letter-spacing: 1px; }
        body .container-fluid .header-bg .header .brand {
          position: absolute;
          top: 30px;
          left: 30px;
          width: 130px; }
        body .container-fluid .header-bg .header .nav_bar {
          width: 100%;
          border-top: 5px solid #fff;
          padding-top: 30px;
          position: fixed;
          top: 0; }
          body .container-fluid .header-bg .header .nav_bar .top {
            width: 100%; }
            body .container-fluid .header-bg .header .nav_bar .top .bars img {
              position: fixed;
              right: 30px;
              top: 30px;
              cursor: pointer;
              width: 40px; }
        body .container-fluid .header-bg .header nav {
          display: flex;
          justify-content: center;
          align-items: center;
          position: fixed;
          top: 5px;
          bottom: 0px;
          width: 40%;
          min-width: 315px;
          background-color: #003863;
          transform: translate3d(-100%, 0, 0);
          transition: all 0.8s cubic-bezier(0.38, -0.02, 0, 1.13);
          z-index: 50;
          overflow-y: auto; }
          body .container-fluid .header-bg .header nav.show-nav {
            transform: translate3d(0, 0, 0); }
          body .container-fluid .header-bg .header nav::-webkit-scrollbar {
            width: 8px; }
          body .container-fluid .header-bg .header nav::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 4px; }
          body .container-fluid .header-bg .header nav::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: #295B85; }
          body .container-fluid .header-bg .header nav .brand {
            position: absolute;
            top: 30px;
            left: 30px; }
            body .container-fluid .header-bg .header nav .brand img {
              width: 130px; }
          body .container-fluid .header-bg .header nav .close-nav {
            height: 50px;
            width: 50px;
            position: absolute;
            z-index: 9;
            background: transparent;
            border: none;
            top: 25px;
            right: 25px;
            border: none;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            line-height: 50px;
            display: inline-block;
            outline: 0;
            cursor: pointer;
            -webkit-transform-origin: center;
            -ms-transform-origin: center;
            transform-origin: center;
            -webkit-transform: scale(0.8);
            -ms-transform: scale(0.8);
            transform: scale(0.8); }
            body .container-fluid .header-bg .header nav .close-nav:hover:before {
              transform: translate3d(0, 0, 0) rotate(0deg); }
            body .container-fluid .header-bg .header nav .close-nav:hover:after {
              transform: translate3d(0, 0, 0) rotate(0deg); }
            body .container-fluid .header-bg .header nav .close-nav:before {
              -webkit-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              transform: translate3d(0, 0, 0) rotate(-45deg);
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              margin-top: -5px;
              margin-left: -28px;
              display: block;
              height: 8px;
              width: 55px;
              background-color: #fff;
              -webkit-transition: all .35s ease-out;
              -o-transition: all .35s ease-out;
              transition: all .35s ease-out; }
            body .container-fluid .header-bg .header nav .close-nav:after {
              -webkit-transform: rotate(-135deg);
              -ms-transform: rotate(-135deg);
              transform: rotate(-135deg);
              content: '';
              position: absolute;
              margin-top: -5px;
              margin-left: -28px;
              top: 50%;
              left: 50%;
              display: block;
              height: 8px;
              width: 55px;
              background-color: #fff;
              -webkit-transition: all .35s ease-out;
              -o-transition: all .35s ease-out;
              transition: all .35s ease-out; }
          body .container-fluid .header-bg .header nav ul {
            margin-top: 50px;
            margin-bottom: 0px;
            margin-left: 0px; }
            body .container-fluid .header-bg .header nav ul li {
              font-size: 20px;
              text-transform: uppercase;
              padding: 14px 0;
              letter-spacing: 8px;
              line-height: 1; }
              body .container-fluid .header-bg .header nav ul li:hover a {
                transform: translate3d(50px, 0, 0);
                text-decoration: none !important; }
                body .container-fluid .header-bg .header nav ul li:hover a:before {
                  opacity: 1; }
                body .container-fluid .header-bg .header nav ul li:hover a:hover {
                  text-decoration: none !important; }
                body .container-fluid .header-bg .header nav ul li:hover a:focus {
                  text-decoration: none !important; }
                body .container-fluid .header-bg .header nav ul li:hover a:active {
                  text-decoration: none !important; }
                body .container-fluid .header-bg .header nav ul li:hover a:link {
                  text-decoration: none !important; }
              body .container-fluid .header-bg .header nav ul li a {
                color: white;
                display: inline-block;
                position: relative;
                transition: all .8s; }
                body .container-fluid .header-bg .header nav ul li a:before {
                  content: '';
                  position: absolute;
                  right: 100%;
                  height: 12px;
                  margin-right: 25px;
                  border-bottom: 3px #fff solid;
                  width: 25px;
                  opacity: 0;
                  transition: all .8s; }
          body .container-fluid .header-bg .header nav .lang {
            position: absolute;
            bottom: 0;
            left: 50px;
            text-transform: uppercase;
            letter-spacing: 5px;
            font-size: 14px; }
        body .container-fluid .header-bg .header h2 {
          font-family: 'Jura-Light', Fallback, sans-serif;
          font-size: 64px;
          margin: auto;
          letter-spacing: 22px;
          text-transform: uppercase;
          display: block;
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          cursor: pointer;
          transition: all .8s ease; }
          body .container-fluid .header-bg .header h2:hover {
            letter-spacing: 28px; }
          body .container-fluid .header-bg .header h2.hide-h2 {
            font-size: 1px;
            opacity: 0; }
  body .references h3 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 25px;
    letter-spacing: 2px;
    font-weight: 400; }
  body .references .references-list {
    margin-bottom: 120px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 30px; }
    body .references .references-list p {
      font-size: 16px;
      background-color: #295B85;
      margin: 7px;
      padding: 2px 7px;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      transition: all .7s ease;
      text-align: center; }
      body .references .references-list p:hover {
        background-color: #fff;
        color: #003863; }
        body .references .references-list p:hover a {
          color: #003863; }
      body .references .references-list p a {
        color: #fff; }
        body .references .references-list p a:hover {
          text-decoration: none !important; }
        body .references .references-list p a:active {
          text-decoration: none !important; }
      body .references .references-list p ul {
        display: none; }
  body .refrence {
    margin-bottom: 175px; }
    body .refrence h1 {
      margin-bottom: 40px;
      text-align: center;
      letter-spacing: 8px; }
    body .refrence p {
      margin-bottom: 25px;
      text-align: center; }
    body .refrence .gm-style .gm-style-iw h3 {
      color: #000;
      font-weight: bold;
      text-align: left; }
    body .refrence .gm-style .gm-style-iw p {
      color: #000;
      margin-bottom: 0px;
      padding: 2px 0 0 0;
      text-align: left; }

.al_product {
  margin-bottom: 150px; }
  .al_product .product-sort-bar {
    display: none !important; }
  .al_product #breadcrumbs {
    margin-top: -100px;
    margin-bottom: 80px; }
    .al_product #breadcrumbs a {
      color: #fff; }
      .al_product #breadcrumbs a:hover {
        text-decoration: underline;
        color: #fff; }
  .al_product .catalog-header {
    text-align: center;
    font-size: 32px;
    margin-bottom: 50px;
    letter-spacing: 2px;
    padding-top: 60px; }
  .al_product .product-subcategories {
    margin-bottom: 50px; }
    .al_product .product-subcategories li {
      font-size: 16px;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      transition: all .4s ease;
      text-align: center;
      width: 33%;
      min-height: 65px;
      position: relative;
      margin: 15px 0; }
      .al_product .product-subcategories li:hover {
        background-color: #fff;
        color: #003863; }
        .al_product .product-subcategories li:hover a {
          color: #003863; }
      .al_product .product-subcategories li a {
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 7px; }
        .al_product .product-subcategories li a:hover {
          text-decoration: none !important; }
        .al_product .product-subcategories li a:active {
          text-decoration: none !important; }
        .al_product .product-subcategories li a:link {
          text-decoration: none !important; }
      .al_product .product-subcategories li ul {
        display: none; }
  .al_product .al_archive {
    box-shadow: none !important;
    border-radius: 2px; }
    .al_product .al_archive a:hover {
      text-decoration: none !important; }
    .al_product .al_archive h3.product-name {
      z-index: 0 !important;
      background-color: rgba(41, 91, 133, 0.8) !important;
      max-width: 100% !important;
      width: 100% !important;
      box-shadow: none !important;
      padding: 5px 8px !important;
      position: absolute !important;
      top: 75% !important;
      bottom: 0 !important;
      left: 0 !important;
      color: #fff !important;
      text-align: center !important; }
  .al_product .product-archive-nav li a {
    background-color: #295B85 !important;
    border-radius: 0px !important; }
  .al_product .product-archive-nav li.active a {
    background-color: #fff !important;
    color: #003863;
    border-radius: 0px !important; }

.ecommerce-product-catalog .fluid-container.bg-product-img {
  background: linear-gradient(0deg, #003863, rgba(0, 56, 99, 0.8), rgba(0, 56, 99, 0.8), #003863), url("../img/sale-bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-bottom: 150px;
  margin-top: 50px; }

.ecommerce-product-catalog .fluid-container.type-al_product {
  background: inherit; }

.product-entry .btn-back-category {
  color: #fff;
  background-color: #295B85;
  padding: 10px 10px 10px 0;
  width: 100px;
  margin-bottom: 20px;
  margin-top: -20px;
  cursor: pointer;
  text-align: center; }
  .product-entry .btn-back-category:hover .fa {
    padding-left: 0px;
    padding-right: 10px; }
  .product-entry .btn-back-category .fa {
    padding: 0 5px;
    transition: .4s all linear; }

.product-entry a.back-to-products {
  color: #fff;
  background-color: #295B85;
  padding: 10px 10px 10px 0;
  width: 150px;
  margin-bottom: 20px;
  margin-top: -20px;
  cursor: pointer;
  text-align: center;
  display: block;
  margin-left: 8px; }
  .product-entry a.back-to-products:hover .fa {
    padding-left: 0px;
    padding-right: 10px; }
  .product-entry a.back-to-products .fa {
    padding: 0 5px;
    transition: .4s all linear; }

.product-entry #product_details_container {
  align-items: flex-start !important; }
  .product-entry #product_details_container #product_details p {
    margin-bottom: 0 !important; }
  .product-entry #product_details_container #product_details .shortdesc {
    border-bottom: none !important;
    opacity: 0 !important;
    transition: all .6s ease !important;
    text-align: center; }
    .product-entry #product_details_container #product_details .shortdesc.show {
      opacity: 1 !important; }
    .product-entry #product_details_container #product_details .shortdesc .order_btn {
      display: block;
      margin: 60px auto 20px;
      background-color: #fff;
      color: #003863;
      text-transform: uppercase;
      letter-spacing: 4px;
      padding: 5px 15px;
      max-width: 220px;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
      transition: all .3s ease; }
      .product-entry #product_details_container #product_details .shortdesc .order_btn:hover, .product-entry #product_details_container #product_details .shortdesc .order_btn:active {
        text-decoration: none !important;
        transform: scale(1.03);
        -webkit-font-smoothing: antialiased;
        -webkit-box-shadow: 0px 7px 28px -9px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 7px 28px -9px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 7px 28px -9px rgba(0, 0, 0, 0.75); }

.boxed .after-product-details {
  border: none !important; }
  .boxed .after-product-details .ic_tab_content.active {
    background: #003863 !important; }
    .boxed .after-product-details .ic_tab_content.active .dataTables_wrapper {
      color: #000; }
      .boxed .after-product-details .ic_tab_content.active .dataTables_wrapper .dataTables_length label {
        color: #fff;
        margin-bottom: 10px; }
        .boxed .after-product-details .ic_tab_content.active .dataTables_wrapper .dataTables_length label select {
          color: #000; }
      .boxed .after-product-details .ic_tab_content.active .dataTables_wrapper .dataTables_filter {
        display: none; }
      .boxed .after-product-details .ic_tab_content.active .dataTables_wrapper .dataTables_info {
        color: #fff;
        margin-top: 15px;
        display: none; }
      .boxed .after-product-details .ic_tab_content.active .dataTables_wrapper .dataTables_paginate {
        margin-top: 15px; }
        .boxed .after-product-details .ic_tab_content.active .dataTables_wrapper .dataTables_paginate .paginate_button.previous {
          text-shadow: none !important;
          color: #fff !important;
          text-decoration: none; }
          .boxed .after-product-details .ic_tab_content.active .dataTables_wrapper .dataTables_paginate .paginate_button.previous.disabled {
            color: #aaa !important; }
            .boxed .after-product-details .ic_tab_content.active .dataTables_wrapper .dataTables_paginate .paginate_button.previous.disabled:before {
              color: #aaa !important;
              content: '\000ab' !important;
              text-shadow: none !important; }
          .boxed .after-product-details .ic_tab_content.active .dataTables_wrapper .dataTables_paginate .paginate_button.previous:before {
            content: '\000ab' !important;
            text-shadow: none !important; }
        .boxed .after-product-details .ic_tab_content.active .dataTables_wrapper .dataTables_paginate .paginate_button.next {
          text-shadow: none !important;
          color: #fff !important;
          text-decoration: none;
          margin-left: 40px; }
          .boxed .after-product-details .ic_tab_content.active .dataTables_wrapper .dataTables_paginate .paginate_button.next.disabled {
            color: #aaa !important; }
            .boxed .after-product-details .ic_tab_content.active .dataTables_wrapper .dataTables_paginate .paginate_button.next.disabled:after {
              color: #aaa !important;
              content: '\000bb' !important;
              text-shadow: none !important; }
          .boxed .after-product-details .ic_tab_content.active .dataTables_wrapper .dataTables_paginate .paginate_button.next:after {
            content: '\000bb' !important;
            text-shadow: none !important; }

.tablepress tbody {
  color: #003863; }
  .tablepress tbody .odd td {
    background-color: #e6f4ff !important; }

.tablepress .sorting {
  background-color: #295B85;
  color: #fff; }
  .tablepress .sorting:hover {
    background-color: #295B85 !important; }

.tablepress .sorting_asc {
  background-color: #295B85 !important;
  color: #003863; }

.tablepress .sorting_desc {
  background-color: #295B85 !important;
  color: #003863; }

#product_search_form {
  float: right;
  display: none;
  z-index: 1;
  padding-bottom: 7px;
  max-width: 200px !important;
  margin-top: -45px; }
  #product_search_form.show {
    display: block; }
  #product_search_form:after {
    display: block;
    clear: both; }
  #product_search_form .product-search-box {
    color: #003863;
    font-weight: bold; }
    #product_search_form .product-search-box::placeholder {
      color: #fff; }
    #product_search_form .product-search-box:active {
      outline: none; }
  #product_search_form .product-search-submit {
    background-color: #295B85 !important; }
    #product_search_form .product-search-submit:active, #product_search_form .product-search-submit:foucs {
      outline: none;
      border: none; }

.product-list p {
  font-size: 22px;
  text-align: center;
  margin: 50px 0; }

.product_search_form {
  display: none !important; }

.hidden-table {
  display: none; }

.order-overlay,
.form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 0;
  visibility: hidden; }
  .order-overlay.show,
  .form-overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 100; }

.order-box,
.form-box {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 950px;
  height: 615px;
  max-height: 90%;
  background-color: #003863;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  padding: 60px 60px 30px 60px;
  overflow-y: scroll; }
  .order-box.show,
  .form-box.show {
    opacity: 1;
    visibility: visible;
    z-index: 101; }
  .order-box::-webkit-scrollbar,
  .form-box::-webkit-scrollbar {
    width: 8px; }
  .order-box::-webkit-scrollbar-track,
  .form-box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px; }
  .order-box::-webkit-scrollbar-thumb,
  .form-box::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #295B85; }
  .order-box .wpcf7-validation-errors,
  .form-box .wpcf7-validation-errors {
    display: none !important; }
  .order-box .wpcf7-response-output,
  .form-box .wpcf7-response-output {
    padding: 1em !important;
    text-align: center !important;
    font-weight: bold !important; }
  .order-box .close-form,
  .form-box .close-form {
    height: 50px;
    width: 50px;
    position: absolute;
    z-index: 9;
    background: transparent;
    border: none;
    top: 25px;
    right: 25px;
    border: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 50px;
    display: inline-block;
    outline: 0;
    cursor: pointer;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8); }
    .order-box .close-form:hover:before,
    .form-box .close-form:hover:before {
      transform: translate3d(0, 0, 0) rotate(0deg); }
    .order-box .close-form:hover:after,
    .form-box .close-form:hover:after {
      transform: translate3d(0, 0, 0) rotate(0deg); }
    .order-box .close-form:before,
    .form-box .close-form:before {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: translate3d(0, 0, 0) rotate(-45deg);
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -5px;
      margin-left: -28px;
      display: block;
      height: 8px;
      width: 55px;
      background-color: #fff;
      -webkit-transition: all .35s ease-out;
      -o-transition: all .35s ease-out;
      transition: all .35s ease-out; }
    .order-box .close-form:after,
    .form-box .close-form:after {
      -webkit-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      transform: rotate(-135deg);
      content: '';
      position: absolute;
      margin-top: -5px;
      margin-left: -28px;
      top: 50%;
      left: 50%;
      display: block;
      height: 8px;
      width: 55px;
      background-color: #fff;
      -webkit-transition: all .35s ease-out;
      -o-transition: all .35s ease-out;
      transition: all .35s ease-out; }
  .order-box .wpcf7-form,
  .form-box .wpcf7-form {
    margin-top: 30px; }
    .order-box .wpcf7-form .flex,
    .form-box .wpcf7-form .flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .order-box .wpcf7-form .flex .wpcf7-not-valid-tip,
      .form-box .wpcf7-form .flex .wpcf7-not-valid-tip {
        color: #ff6666; }
      .order-box .wpcf7-form .flex label,
      .form-box .wpcf7-form .flex label {
        color: #fff;
        font-weight: bold;
        letter-spacing: 2px;
        width: 48%;
        margin-bottom: 15px; }
        .order-box .wpcf7-form .flex label.full-width,
        .form-box .wpcf7-form .flex label.full-width {
          width: 100%; }
        .order-box .wpcf7-form .flex label input,
        .order-box .wpcf7-form .flex label textarea,
        .form-box .wpcf7-form .flex label input,
        .form-box .wpcf7-form .flex label textarea {
          width: 100%;
          background-color: #295B85;
          border: none;
          margin-top: 2px;
          min-height: 32px;
          line-height: 26px;
          font-weight: 400;
          padding: 0 10px; }
          .order-box .wpcf7-form .flex label input:focus,
          .order-box .wpcf7-form .flex label textarea:focus,
          .form-box .wpcf7-form .flex label input:focus,
          .form-box .wpcf7-form .flex label textarea:focus {
            outline: none; }
        .order-box .wpcf7-form .flex label textarea,
        .form-box .wpcf7-form .flex label textarea {
          height: 180px !important; }
        .order-box .wpcf7-form .flex label span,
        .form-box .wpcf7-form .flex label span {
          display: block; }
      .order-box .wpcf7-form .flex input[type=submit],
      .form-box .wpcf7-form .flex input[type=submit] {
        margin: 15px auto 0 auto;
        background-color: #fff;
        color: #003863;
        text-transform: uppercase;
        text-align: center;
        border: none;
        padding: 10px 24px;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 2px;
        transition: all .7s linear; }
        .order-box .wpcf7-form .flex input[type=submit]:hover,
        .form-box .wpcf7-form .flex input[type=submit]:hover {
          text-decoration: none !important;
          background-color: #295B85;
          color: #fff; }
      .order-box .wpcf7-form .flex .checkbox,
      .form-box .wpcf7-form .flex .checkbox {
        margin-top: 0px !important;
        margin-left: 3px !important; }
        .order-box .wpcf7-form .flex .checkbox .wpcf7-list-item,
        .form-box .wpcf7-form .flex .checkbox .wpcf7-list-item {
          display: block !important;
          padding: 5px 0 !important; }

.page-id-71 h1 {
  letter-spacing: 10px; }

.page-id-71 h2 {
  text-align: center;
  letter-spacing: 2px;
  font-size: 26px;
  margin-bottom: 50px;
  line-height: 1.5; }

.page-id-71 ul {
  margin-left: 60px;
  margin-bottom: 150px; }
  .page-id-71 ul li {
    padding: 10px 30px 10px 0px;
    font-size: 16px;
    line-height: 1.2; }
    .page-id-71 ul li:before {
      content: '';
      position: absolute;
      right: 100%;
      height: 12px;
      margin-right: 5px;
      border-bottom: 3px #fff solid;
      width: 25px;
      opacity: 1; }

.page-id-71 .owl-theme .owl-nav {
  background-color: #003863; }

.page-id-71 .owl-theme .owl-nav [class*=owl-] {
  margin: 10px 35px !important;
  background-color: transparent !important; }

.page-id-71 .owl-carousel {
  margin-bottom: 170px; }
  .page-id-71 .owl-carousel .owl-item {
    height: 220px;
    display: flex;
    justify-content: center; }
  .page-id-71 .owl-carousel .item {
    display: flex;
    justify-content: center;
    align-items: center; }
    .page-id-71 .owl-carousel .item img {
      width: 80% !important; }
  .page-id-71 .owl-carousel .owl-stage-outer {
    background-color: #fff; }

.page-child .page {
  margin-bottom: 150px; }
  .page-child .page h1 {
    letter-spacing: 5px; }
  .page-child .page h2 {
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 20px; }
  .page-child .page h2.center {
    font-size: 22px;
    line-height: 1.4;
    margin-bottom: 35px;
    text-align: center; }
  .page-child .page a {
    color: #fff;
    font-weight: bold; }
    .page-child .page a:hover {
      text-decoration: none; }
  .page-child .page ul {
    margin-left: 40px;
    margin-bottom: 10px; }
    .page-child .page ul li {
      padding: 10px 30px 10px 10px;
      font-size: 16px;
      line-height: 1.2;
      position: relative; }
      .page-child .page ul li:before {
        content: '';
        position: absolute;
        right: 100%;
        height: 12px;
        margin-right: 5px;
        border-bottom: 3px #fff solid;
        width: 25px;
        opacity: 1; }
  .page-child .page .white-btn {
    display: block;
    margin: 0 auto;
    text-align: center;
    color: #003863;
    background-color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 250px;
    padding: 10px;
    font-size: 17px;
    margin-top: 40px;
    transition: .3s all ease; }
    .page-child .page .white-btn:hover {
      text-decoration: none;
      transform: scale(1.04); }
  .page-child .page .back-to-offer {
    font-size: 18px;
    margin-top: -25px;
    margin-bottom: 30px;
    display: block;
    font-weight: normal;
    background: #295B85;
    width: 200px;
    text-align: center;
    padding: 10px;
    transition: .6s all ease; }
    .page-child .page .back-to-offer:hover {
      text-decoration: none;
      background-color: #fff;
      font-weight: bold;
      color: #003863; }

.page-child.page-template-page-photo-template .photo-template {
  display: flex; }
  @media all and (max-width: 1024px) {
    .page-child.page-template-page-photo-template .photo-template {
      flex-direction: column-reverse; } }
  .page-child.page-template-page-photo-template .photo-template .right-photo {
    margin-top: 100px;
    max-width: 40%;
    padding: 0 15px; }
    @media all and (max-width: 1024px) {
      .page-child.page-template-page-photo-template .photo-template .right-photo {
        width: 85%;
        max-width: 85%;
        margin: 50px auto !important;
        padding: 0px; } }
    .page-child.page-template-page-photo-template .photo-template .right-photo img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      height: auto; }

.not-found,
.search-results {
  margin-bottom: 150px; }
  .not-found .page-header,
  .search-results .page-header {
    border-bottom: none; }
  .not-found .page-content,
  .search-results .page-content {
    text-align: center; }
    .not-found .page-content p,
    .search-results .page-content p {
      font-size: 22px; }
      .not-found .page-content p a,
      .search-results .page-content p a {
        color: #8ab7db; }
        .not-found .page-content p a:hover,
        .search-results .page-content p a:hover {
          text-decoration: none;
          color: #fff; }
    .not-found .page-content .search-form,
    .search-results .page-content .search-form {
      margin-top: 50px; }
      .not-found .page-content .search-form .screen-reader-text,
      .search-results .page-content .search-form .screen-reader-text {
        display: block;
        font-size: 20px;
        margin-bottom: 15px; }
      .not-found .page-content .search-form .search-field,
      .search-results .page-content .search-form .search-field {
        background-color: #295B85;
        border: none;
        margin-top: 2px;
        min-height: 32px;
        line-height: 26px;
        font-weight: 400;
        padding: 0 10px; }
        .not-found .page-content .search-form .search-field::placeholder,
        .search-results .page-content .search-form .search-field::placeholder {
          visibility: hidden;
          display: none; }
        .not-found .page-content .search-form .search-field:focus,
        .search-results .page-content .search-form .search-field:focus {
          outline: none; }
      .not-found .page-content .search-form input[type=submit],
      .search-results .page-content .search-form input[type=submit] {
        display: block;
        margin: 15px auto 0 auto;
        background-color: #fff;
        color: #003863;
        text-transform: uppercase;
        text-align: center;
        border: none;
        padding: 10px 24px;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 2px;
        transition: all .7s linear; }
        .not-found .page-content .search-form input[type=submit]:hover,
        .search-results .page-content .search-form input[type=submit]:hover {
          text-decoration: none !important;
          background-color: #295B85;
          color: #fff; }
        .not-found .page-content .search-form input[type=submit]:focus,
        .search-results .page-content .search-form input[type=submit]:focus {
          outline: none; }

.search-results .content-area {
  margin-bottom: 120px; }
  .search-results .content-area article {
    margin-bottom: 50px; }
    .search-results .content-area article h2 {
      text-align: center;
      font-size: 22px; }
      .search-results .content-area article h2 a {
        color: #8ab7db; }
        .search-results .content-area article h2 a:hover {
          text-decoration: none;
          color: #fff; }

@font-face {
  font-family: 'Jura';
  src: url("../font/jura/JuraMedium.eot");
  src: url("../font/jura/JuraMedium.eot?#iefix") format("embedded-opentype"), url("../font/jura/JuraMedium.woff2") format("woff2"), url("../font/jura/JuraMedium.woff") format("woff"), url("../font/jura/JuraMedium.ttf") format("truetype"); }

@font-face {
  font-family: 'Jura-Light';
  src: url("../font/jura/JuraLight.eot");
  src: url("../font/jura/JuraLight.eot?#iefix") format("embedded-opentype"), url("../font/jura/JuraLight.woff2") format("woff2"), url("../font/jura/JuraLight.woff") format("woff"), url("../font/jura/JuraLight.ttf") format("truetype"); }

@font-face {
  font-family: 'Jura-Bold';
  src: url("../font/jura/JuraDemiBold.eot");
  src: url("../font/jura/JuraDemiBold.eot?#iefix") format("embedded-opentype"), url("../font/jura/JuraDemiBold.woff2") format("woff2"), url("../font/jura/JuraDemiBold.woff") format("woff"), url("../font/jura/JuraDemiBold.ttf") format("truetype"); }

.aligncenter {
  display: block !important;
  margin: 0 auto !important; }

.alignright {
  display: block !important;
  margin: 0 0 0 auto !important; }

body {
  background: #003863;
  color: #fff;
  font-family: 'Jura-Light', Fallback, sans-serif; }
  body.home {
    overflow-x: hidden; }
    body.home .container-fluid {
      padding: 0; }
      body.home .container-fluid .header-bg {
        width: 100%;
        height: 100vh;
        min-height: 500px;
        background-size: contain;
        background-repeat: none;
        background-position: center;
        margin-bottom: 0px; }
        body.home .container-fluid .header-bg .sow-google-map-canvas {
          height: 100vh !important;
          min-height: 500px; }
        body.home .container-fluid .header-bg .header {
          width: 100%;
          height: 100vh;
          min-height: 500px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 50; }
          body.home .container-fluid .header-bg .header.hide-header {
            position: static; }
          body.home .container-fluid .header-bg .header .EN {
            color: #fff;
            position: absolute;
            top: 26px;
            right: 90px;
            font-size: 18px;
            letter-spacing: 1px; }
          body.home .container-fluid .header-bg .header .brand {
            position: absolute;
            top: 30px;
            left: 30px;
            width: 130px; }
          body.home .container-fluid .header-bg .header .nav_bar {
            width: 100%;
            border-top: 5px solid #fff;
            padding-top: 30px;
            position: fixed;
            top: 0; }
            body.home .container-fluid .header-bg .header .nav_bar .top {
              width: 100%; }
              body.home .container-fluid .header-bg .header .nav_bar .top .bars img {
                position: fixed;
                right: 30px;
                top: 30px;
                cursor: pointer;
                width: 40px; }
          body.home .container-fluid .header-bg .header nav {
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            top: 5px;
            bottom: 0px;
            width: 40%;
            min-width: 315px;
            background-color: #003863;
            transform: translate3d(-100%, 0, 0);
            transition: all 0.8s cubic-bezier(0.38, -0.02, 0, 1.13);
            z-index: 50;
            overflow-y: auto; }
            body.home .container-fluid .header-bg .header nav.show-nav {
              transform: translate3d(0, 0, 0); }
            body.home .container-fluid .header-bg .header nav::-webkit-scrollbar {
              width: 8px; }
            body.home .container-fluid .header-bg .header nav::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              border-radius: 4px; }
            body.home .container-fluid .header-bg .header nav::-webkit-scrollbar-thumb {
              border-radius: 4px;
              background-color: #295B85; }
            body.home .container-fluid .header-bg .header nav .brand {
              position: absolute;
              top: 30px;
              left: 30px; }
              body.home .container-fluid .header-bg .header nav .brand img {
                width: 130px; }
            body.home .container-fluid .header-bg .header nav .close-nav {
              height: 50px;
              width: 50px;
              position: absolute;
              z-index: 9;
              background: transparent;
              border: none;
              top: 25px;
              right: 25px;
              border: none;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              line-height: 50px;
              display: inline-block;
              outline: 0;
              cursor: pointer;
              -webkit-transform-origin: center;
              -ms-transform-origin: center;
              transform-origin: center;
              -webkit-transform: scale(0.8);
              -ms-transform: scale(0.8);
              transform: scale(0.8); }
              body.home .container-fluid .header-bg .header nav .close-nav:hover:before {
                transform: translate3d(0, 0, 0) rotate(0deg); }
              body.home .container-fluid .header-bg .header nav .close-nav:hover:after {
                transform: translate3d(0, 0, 0) rotate(0deg); }
              body.home .container-fluid .header-bg .header nav .close-nav:before {
                -webkit-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                transform: translate3d(0, 0, 0) rotate(-45deg);
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -5px;
                margin-left: -28px;
                display: block;
                height: 8px;
                width: 55px;
                background-color: #fff;
                -webkit-transition: all .35s ease-out;
                -o-transition: all .35s ease-out;
                transition: all .35s ease-out; }
              body.home .container-fluid .header-bg .header nav .close-nav:after {
                -webkit-transform: rotate(-135deg);
                -ms-transform: rotate(-135deg);
                transform: rotate(-135deg);
                content: '';
                position: absolute;
                margin-top: -5px;
                margin-left: -28px;
                top: 50%;
                left: 50%;
                display: block;
                height: 8px;
                width: 55px;
                background-color: #fff;
                -webkit-transition: all .35s ease-out;
                -o-transition: all .35s ease-out;
                transition: all .35s ease-out; }
            body.home .container-fluid .header-bg .header nav ul {
              margin-top: 50px;
              margin-bottom: 0px;
              margin-left: 0px; }
              body.home .container-fluid .header-bg .header nav ul li {
                font-size: 20px;
                text-transform: uppercase;
                padding: 14px 0;
                letter-spacing: 8px;
                line-height: 1; }
                body.home .container-fluid .header-bg .header nav ul li:hover a {
                  transform: translate3d(50px, 0, 0);
                  text-decoration: none !important; }
                  body.home .container-fluid .header-bg .header nav ul li:hover a:before {
                    opacity: 1; }
                  body.home .container-fluid .header-bg .header nav ul li:hover a:hover {
                    text-decoration: none !important; }
                  body.home .container-fluid .header-bg .header nav ul li:hover a:focus {
                    text-decoration: none !important; }
                  body.home .container-fluid .header-bg .header nav ul li:hover a:active {
                    text-decoration: none !important; }
                  body.home .container-fluid .header-bg .header nav ul li:hover a:link {
                    text-decoration: none !important; }
                body.home .container-fluid .header-bg .header nav ul li a {
                  color: white;
                  display: inline-block;
                  position: relative;
                  transition: all .8s; }
                  body.home .container-fluid .header-bg .header nav ul li a:before {
                    content: '';
                    position: absolute;
                    right: 100%;
                    height: 12px;
                    margin-right: 25px;
                    border-bottom: 3px #fff solid;
                    width: 25px;
                    opacity: 0;
                    transition: all .8s; }
            body.home .container-fluid .header-bg .header nav .lang {
              position: absolute;
              bottom: 0;
              left: 50px;
              text-transform: uppercase;
              letter-spacing: 5px;
              font-size: 14px; }
          body.home .container-fluid .header-bg .header h2 {
            font-family: 'Jura-Light', Fallback, sans-serif;
            font-size: 64px;
            margin: auto;
            letter-spacing: 22px;
            text-transform: uppercase;
            display: block;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            cursor: pointer;
            transition: all .8s ease; }
            body.home .container-fluid .header-bg .header h2:hover {
              letter-spacing: 28px; }
            body.home .container-fluid .header-bg .header h2.hide-h2 {
              font-size: 1px;
              opacity: 0; }

@keyframes MoveUpDown {
  0% {
    bottom: 10px; }
  50% {
    bottom: 40px; }
  100% {
    bottom: 10px; } }
          body.home .container-fluid .header-bg .header .scroll-down {
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            animation: MoveUpDown 2.8s linear infinite;
            cursor: pointer; }
            body.home .container-fluid .header-bg .header .scroll-down img {
              width: 42px;
              height: auto; }
          body.home .container-fluid .header-bg .header .facebook {
            position: absolute;
            bottom: 30px;
            right: 35px; }
            body.home .container-fluid .header-bg .header .facebook .fa {
              font-size: 36px;
              color: #fff;
              transition: .2s all linear; }
              body.home .container-fluid .header-bg .header .facebook .fa:hover {
                color: #ccc; }
          body.home .container-fluid .header-bg .header .youtube {
            position: absolute;
            bottom: 30px;
            right: 80px; }
            body.home .container-fluid .header-bg .header .youtube .fa {
              font-size: 36px;
              color: #fff;
              transition: .2s all linear; }
              body.home .container-fluid .header-bg .header .youtube .fa:hover {
                color: #ccc; }
        body.home .container-fluid .header-bg .header-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100vh;
          min-height: 500px;
          background-color: rgba(11, 60, 107, 0.7);
          transition: 1s all ease; }
          body.home .container-fluid .header-bg .header-overlay.hide-overlay {
            transform: translate3d(0, -100%, 0); }
  body .container #about {
    padding-top: 150px; }
    body .container #about .column-l {
      padding-right: 65px; }
    body .container #about .column-r {
      padding-left: 65px; }
    body .container #about .column-m p {
      padding-top: 50px;
      max-width: 500px;
      margin: 0 auto; }
    body .container #about p {
      text-align: center;
      font-size: 18px; }
  body .service-bg {
    min-height: 1191px;
    height: auto;
    background-image: url("../img/service-bg.png");
    background-repeat: none;
    background-size: cover;
    background-position: center;
    padding-top: 150px;
    margin-bottom: 150px; }
    body .service-bg #service {
      height: 100px; }
    body .service-bg .box {
      height: 280px;
      position: relative; }
      body .service-bg .box:hover .pic img {
        transform: scale(1);
        opacity: 1;
        box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.6);
        cursor: pointer; }
      body .service-bg .box:hover h3 {
        opacity: 0;
        z-index: 0; }
      body .service-bg .box .pic {
        position: absolute;
        top: 0;
        left: 0;
        padding: 12px; }
        body .service-bg .box .pic img {
          border: 10px #fff solid;
          max-width: 426px;
          width: 100%;
          height: auto;
          transform: scale(0);
          opacity: 0;
          transition: opacity 1.6s ease, transform .8s ease, box-shadow .8s ease .8s; }
      body .service-bg .box h3 {
        cursor: pointer;
        text-transform: uppercase;
        font-size: 20px;
        letter-spacing: 7px;
        text-align: center;
        width: 100%;
        position: relative;
        top: 35%;
        line-height: 1.3;
        display: block;
        z-index: 1;
        transition: all .5s ease .2s; }
        body .service-bg .box h3 a {
          color: #fff;
          text-decoration: none; }
          body .service-bg .box h3 a:hover {
            text-decoration: none; }
  body #reference {
    padding-top: 100px;
    margin-top: -100px; }
  body .reference {
    margin-bottom: 150px; }
    body .reference p {
      padding: 6px 35px; }
      body .reference p:hover a {
        transform: translate3d(50px, 0, 0);
        text-decoration: none !important; }
        body .reference p:hover a:before {
          opacity: 1; }
      body .reference p a {
        color: white;
        display: inline-block;
        position: relative;
        transition: all .8s; }
        body .reference p a:before {
          content: '';
          position: absolute;
          right: 100%;
          height: 12px;
          margin-right: 25px;
          border-bottom: 3px #fff solid;
          width: 25px;
          opacity: 0;
          transition: all .8s; }
  body .sale-bg {
    height: 450px;
    background: linear-gradient(0deg, rgba(0, 56, 99, 0.7), rgba(0, 56, 99, 0.7)), url("../img/sale-bg.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    margin-bottom: 150px; }
    body .sale-bg .sale-content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 450px; }
      body .sale-bg .sale-content h1 {
        font-family: 'Jura', Fallback, sans-serif;
        color: #003863;
        background-color: #fff;
        padding: 20px 40px;
        display: block;
        margin-top: 100px;
        transition: all .3s ease; }
        body .sale-bg .sale-content h1:hover, body .sale-bg .sale-content h1:active {
          text-decoration: none !important;
          transform: scale(1.03);
          -webkit-box-shadow: 0px 7px 28px -9px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 0px 7px 28px -9px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 7px 28px -9px rgba(0, 0, 0, 0.75); }
        body .sale-bg .sale-content h1:after {
          display: none; }
        body .sale-bg .sale-content h1 a {
          color: #003863; }
          body .sale-bg .sale-content h1 a:hover, body .sale-bg .sale-content h1 a:visited, body .sale-bg .sale-content h1 a:active {
            color: #003863;
            text-decoration: none !important; }
  body .footer-content h2 {
    text-align: center;
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 15px;
    margin-bottom: 75px;
    line-height: 1.3; }
  body .footer-content .address,
  body .footer-content .phone,
  body .footer-content .mail {
    width: 130px;
    margin: 0 auto;
    position: relative; }
  body .footer-content .address:before {
    font-family: FontAwesome;
    content: "\f0ac";
    position: absolute;
    left: -40px;
    top: 15px;
    font-size: 20px; }
  body .footer-content .phone:before {
    font-family: FontAwesome;
    content: "\f095";
    position: absolute;
    left: -40px;
    top: 15px;
    font-size: 20px; }
  body .footer-content .mail:before {
    font-family: FontAwesome;
    content: "\f0e0";
    position: absolute;
    left: -40px;
    top: 15px;
    font-size: 20px; }
  body .footer-content .white-btn {
    font-family: 'Jura', Fallback, sans-serif;
    letter-spacing: 8px;
    background: #fff;
    padding: 10px 20px;
    color: #003863;
    text-transform: uppercase;
    display: block;
    text-align: center;
    margin: 70px auto;
    font-size: 24px;
    transition: all .3s ease; }
    body .footer-content .white-btn:hover, body .footer-content .white-btn:active {
      text-decoration: none !important;
      transform: scale(1.03);
      -webkit-box-shadow: 0px 7px 28px -9px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 7px 28px -9px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 7px 28px -9px rgba(0, 0, 0, 0.75); }
  body .footer-content .fb {
    color: #fff !important;
    text-decoration: none !important; }
    body .footer-content .fb:hover {
      text-decoration: none !important;
      color: #003863 !important; }
    body .footer-content .fb .facebook {
      font-size: 36px;
      margin: 30px;
      background-color: #003863;
      width: 70px;
      height: 70px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 35px;
      transition: all .3s ease;
      cursor: pointer;
      color: #fff; }
      body .footer-content .fb .facebook:hover {
        background-color: #fff;
        color: #003863; }
  body .footer-map {
    height: 600px;
    margin-top: 100px;
    position: relative; }
    body .footer-map .sow-google-map-canvas {
      height: 600px !important; }
    body .footer-map .footer-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 600px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      transition: 1s all ease;
      cursor: pointer; }
      body .footer-map .footer-overlay.hide-foooter-overlay {
        height: 0; }
    body .footer-map .web-info {
      position: absolute;
      z-index: 1;
      color: white;
      text-align: center;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%); }
      body .footer-map .web-info p {
        padding-top: 0px; }
        body .footer-map .web-info p a {
          color: #fff; }
          body .footer-map .web-info p a:visited {
            color: #fff; }

.cookie-notice-container #cn-notice-text {
  color: #000 !important;
  font-size: 15px !important; }

.cookie-notice-container .cn-set-cookie.button.wp-default {
  background-color: red; }

.cookie-notice-container .button {
  padding: 4px 15px;
  border: solid #003863 1px;
  font-size: 15px;
  color: #003863;
  text-transform: upperacase; }

img.alignleft {
  float: left;
  padding: 20px; }
  img.alignleft:after {
    content: '';
    display: block;
    clear: both; }

img.aligncenter {
  display: block;
  margin: 0 auto;
  padding: 20px; }

img.alignright {
  float: right;
  padding: 20px; }
  img.alignright:after {
    content: '';
    display: block;
    clear: both; }

img.alignnone {
  display: block;
  margin-left: 0px; }

.gallery {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; }
  .gallery .gallery-item {
    padding: 20px; }

#pwbox-2944 {
  color: black; }

.post-password-form input {
  color: black; }
