.page-child {

	.page {
		margin-bottom: 150px;

		h1 {
			letter-spacing: 5px;
		}

		h2 {
			font-size: 18px;
			line-height: 1.4;
			margin-bottom: 20px;
		}

		h2.center {
			font-size: 22px;
			line-height: 1.4;
			margin-bottom: 35px;
			text-align: center;
		}

		a {
			color: #fff;
			font-weight: bold;

			&:hover {
				text-decoration: none;
			}
		}

		ul {
			margin-left: 40px;
			margin-bottom: 10px;

			li {
				padding: 10px 30px 10px 10px;
				font-size: 16px;
				line-height: 1.2;
				position:relative;

				&:before {
					content: '';
					position:absolute;
					right: 100%;
					height: 12px;
					margin-right: 5px;
					border-bottom: 3px #fff solid;
					width: 25px;
					opacity:1;
				}
			}
		}

		.white-btn {
			display: block;
		    margin: 0 auto;
		    text-align: center;
		    color: #003863;
		    background-color: #fff;
		    text-transform: uppercase;
		    letter-spacing: 1px;
		    width: 250px;
		    padding: 10px;
		    font-size: 17px;
		    margin-top: 40px;
		    transition: .3s all ease;

		    &:hover {
		    	text-decoration: none;
		    	transform: scale(1.04);
		    }
		}

		.back-to-offer {
			font-size: 18px;
		    margin-top: -25px;
		    margin-bottom: 30px;
		    display: block;
		    font-weight: normal;
		    background: #295B85;
		    width: 200px;
		    text-align: center;
		    padding: 10px;
		    transition: .6s all ease;

		    &:hover {
		    	text-decoration: none;
				background-color: #fff;
				font-weight: bold;
				color: $bg-color;
		    }
		}  
	}

	&.page-template-page-photo-template {
		.photo-template {
			display: flex;

			@media all and (max-width: 1024px) {
				flex-direction: column-reverse;
			}

			.right-photo {
				margin-top:100px;
				max-width: 40%;
				padding: 0 15px;

				@media all and (max-width: 1024px) {
					width: 85%;
					max-width: 85%;
					margin: 50px auto !important;
					padding: 0px;
				}

				img {
					display: block;
					margin: 0 auto;
					max-width: 100%;
					height: auto;
				}
			}
		}
	}
}